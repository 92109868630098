import { defineComponent } from 'vue';
import { useRoute } from 'vue-router';
import { ElConfigProvider } from 'element-plus';
import zhCn from 'element-plus/lib/locale/lang/zh-cn';
export default defineComponent({
  components: {
    ElConfigProvider
  },
  setup() {
    const route = useRoute();
    return {
      zIndex: 3000,
      route,
      zhCn
    };
  }
});