import { createRouter, createWebHistory } from 'vue-router'
import RoutsMap from './routes'

const routes = [
    {
        path: '/',
        name: 'Index',
        redirect: '/bff/home',
    },
    {
        path: '/login',
        name: 'Login',
        component: () => import('../views/login/Index.vue'),
        meta: {
            title: '登录',
        },
    },
    {
        path: '/test',
        component: () => import('../views/example/detail.vue')
    },
    ...RoutsMap,
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
})

// 全局前置路由守卫，进入路由前对登录态以及权限进行判断 todo
router.beforeEach(to => {
    if (to.meta) {
        document.title = to.meta.title || ''
    }
    return true
})

export default router
