export default [
    {
        path: '/bff',
        name: 'bff',
        component: () => import('../views/layout/Index.vue'),
        meta: {
            title: '页面配置',
            icon: 'HomeFilled',
        },
        children: [
            {
                path: 'home',
                name: 'bff-home',
                component: () => import('../views/bff/Home.vue'),
                meta: {
                    title: '主页配置',
                },
            },
        ],
    },
    {
        path: '/project',
        name: 'project',
        component: () => import('../views/layout/Index.vue'),
        meta: {
            title: '项目管理',
            icon: 'Menu',
        },
        children: [
            {
                path: 'list',
                name: 'project-list',
                component: () => import('../views/project/Index.vue'),
                meta: {
                    title: '项目列表',
                },
            },
            {
                path: 'tag',
                name: 'tag-list',
                component: () => import('../views/project/Tag.vue'),
                meta: {
                    title: '标签列表',
                },
            },
        ],
    },
    {
        path: '/article',
        name: 'article',
        component: () => import('../views/layout/Index.vue'),
        meta: {
            title: '资讯管理',
            icon: 'Management',
        },
        children: [
            {
                path: 'list',
                name: 'article-list',
                component: () => import('../views/article/Index.vue'),
                meta: {
                    title: '资讯列表',
                },
            },
        ],
    },
    {
        path: '/user',
        name: 'user',
        component: () => import('../views/layout/Index.vue'),
        meta: {
            title: '员工管理',
            icon: 'Avatar',
        },
        children: [
            {
                path: 'member',
                name: 'member-list',
                component: () => import('../views/user/Member.vue'),
                meta: {
                    title: '小程序员工列表',
                },
            },
            {
                path: 'admin',
                name: 'admin-list',
                component: () => import('../views/user/Admin.vue'),
                meta: {
                    title: '管理员列表',
                    role: 'admin'
                },
            },
        ],
    },
]
